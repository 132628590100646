import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Page } from '@common/clients/api';
import { IntroBlock, IntroBlockProps } from '@web/molecules/IntroBlock';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { DefaultServerSideProps } from '@web/routing';
import styles from '@web/templates/CustomPage/CustomPage.module.scss';

export interface State {
    page?: Page;
}

export interface Props extends DefaultServerSideProps {
    page?: Page;
}

// Homerun widget
const search = '<div id="homerun-jobs-widget">&nbsp;</div>';
const replace = `
      <div id="homerun-jobs-widget"></div>
      <script src="https://d3s4clg74dg0wr.cloudfront.net/widget/js/main.js?v=7.0.0"></script>
      <script>try{Homerun.lang="nl";Homerun.loadWidget("hfohtvdrc9wxjc4bo0wg");}catch(e){};</script>
    `;

const replacePageText = (originalText: string) => {
    return originalText.replace(search, replace);
};

export const CustomPage: FC<Props> = ({ page, contextData }) => {
    if (!page) throw new Error("page can't be undefined");

    const introBlockData: IntroBlockProps = {
        superTitle: contextData.platform.name,
        title: page.title || '',
        description: '',
        additionalClasses: ['underline'],
    };
    const __meta = useTranslation('meta').t;

    return (
        <>
            <MetaHead
                title={page.title}
                description={
                    page.metaDescription ||
                    __meta('label.custom-page', {
                        pageName: page.title,
                        domainName: contextData.context.name,
                    })
                }
            />
            <div className="main-content">
                <IntroBlock {...introBlockData} />
                {page.text ? ( // TODO: Replace with HTMLToReact
                    <div
                        className={styles.CustomPage}
                        dangerouslySetInnerHTML={{ __html: replacePageText(page.text as string) || '' }}
                    />
                ) : null}
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
